<template>
    <v-autocomplete
        v-model="searchId"
        :items="resultSearchSwirch"
        :loading="isLoadingSwitch"
        :search-input.sync="searchSwirch"
        :dense="denseForm || false"
        :outlined="outlinedForm || false"
        :hide-no-data="!searchSwirch || searchSwirch.length < 2 || searchSwirch.length > 1 && resultSearchSwirch.length != 0"
        :rules="rules"
        hide-selected
        hide-details="auto"
        item-text="ip"
        item-value="id"
        label="Батьківський Комутатор"
        placeholder="Введіть номер будинку"
        :prepend-icon="iconForm || ''"
        clearable
        no-data-text="Не знайдено"
        no-filter
    >
    <template v-slot:item="data">
      <div>
        <small class="mr-1">{{ data.item.street }} {{ data.item.house }} {{ data.item.letter }}</small> 
        <br>
        <small class="grey--text"> ({{ data.item.model.name }})</small>
        <small class="primary--text"> ({{ data.item.ip }})</small>
      </div>
    </template>
    </v-autocomplete>
</template>

<script>
import axios from "axios"
export default {
  props: ["outlinedForm", "iconForm", "denseForm", "clearSearchSwirch", "rules", "item"],
  data: () => ({
      searchSwirch: '',
      isLoadingSwitch: false,
      resultSearchSwirch: [],
      searchId: null
  }),
  created: function () {
    this.debounceSearchLoading = require('lodash').debounce(this.getSwitch, 500);
  },
  watch: {
    item(){
      console.log(this.item)
    },
    searchId(){
      let searchData = this.resultSearchSwirch.find(x => x.id == this.searchId) // Шукаємо вулицю в об'єкті щоб витягнути текстову назву для другого аргументу нижче
      this.$emit("search-new-id", this.searchId, searchData && searchData.id || '')
    },
    clearSearchSwirch(){
      console.log("clearSearchSwirch")
      this.resultSearchSwirch = []
      this.searchId = null
      this.SearchSwirch = ''
    },
    searchSwirch(val){
      if (!val || val.length < 1) {
        this.resultSearchSwirch = []
        this.searchId = null
        //console.log("search no")
        return
      }
      if(!this.searchId){
        this.debounceSearchLoading(val)
      }
      
    }
  },
  methods: {
    getSwitch(val){
      this.isLoadingSwitch = true
      axios.get(`${this.$router.app.devUrlServiceSwitches}/api_network/search_switch/`, {params: {house: val}})
      .then(res => {
        this.resultSearchSwirch = res.data
      })
      .catch(() => {
        //console.log(err)
      })
      .finally(() => (this.isLoadingSwitch = false))
    }
  } 
}
</script>