<template>
  <v-container class="switch" :style="{'max-width': '1500px'}">
    <v-navigation-drawer
      v-model="drawer"
      app
      left
      :clipped="$vuetify.breakpoint.mdAndUp"
    >
        
        <!--<v-select
         dense
          v-model="districtsTab"
          :items="districtsItems"
          label="Оператор"
          item-text="title"
          item-value="id"
          hide-details=""
          outlined
          class="mt-4  px-2"
        ></v-select>-->
        

      <v-list
      v-if="false && user.operator_id == 1"
        dense
      >
        
        <v-subheader>Оператори</v-subheader>
        <v-list-item-group v-model="districtsTab" color="primary">
          <v-list-item
            v-for="(item, i) in districtsItems"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>  
        </v-list-item-group>
      </v-list>
      
      <v-list dense>
        <v-subheader>Дільниці 
          <v-progress-circular
            v-if="loadingDistricts"
            indeterminate
            class="ml-1"
            :size="15"
            :width="2"
          ></v-progress-circular>
          
        </v-subheader>
        <v-list-item-group v-model="subDistrictsTab" color="primary">
          <v-list-item @click="getMainSwitches()" :disabled="loading">
            <v-list-item-content>
              <v-list-item-title>Вузлові комутатори <v-icon color="accent" small class="ml-1">mdi-star</v-icon></v-list-item-title>
            </v-list-item-content>
          </v-list-item>  
          <v-list-item 
            v-for="(item, i) in subDistrictsItems"
            :disabled="loading"
            :key="i"
            @click="getSwitchTree(item.switch_id)"
          >
            <v-list-item-content class="py-0">
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon v-if="user && user.user_type != 2">
              <v-icon class="district-action" small @click.stop="deleteDistrict(item)">mdi-delete-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>  
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2" v-if="user && user.user_type != 2">
          <v-btn block x-small @click="showModelsSwitch = true">
            Моделі Комутаторів
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <keep-alive>
      <Search ref="searchSwitches" v-on:clear-pre-search="prefixSearch = ''" v-on:apply-search="startSearch"  :prefixSearch="prefixSearch"/>
    </keep-alive>

    <v-app-bar
      color="secondary lighten-1"
      dark
    >
    <v-app-bar-nav-icon v-if="!drawer" @click="drawer = true"></v-app-bar-nav-icon>
    <v-btn v-else @click="drawer = false" icon ><v-icon >mdi-chevron-left</v-icon></v-btn>
      <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''"><h2 class="mr-6 accent--text">Комутатори</h2></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn v-if="switches && switches.length != 0" icon @click="activatedAll()" title="Розгорнути дерево" >
        <v-icon v-if="activeAll">mdi-unfold-less-horizontal</v-icon>
        <v-icon v-else>mdi-unfold-more-horizontal</v-icon>
      </v-btn>

      

      
    </v-app-bar>

    

    <div class="mt-5 mb-10">
      <v-row class="mt-12" justify="center" v-if="loading">
        <v-progress-circular
            v-if="loading"
            indeterminate
            :size="30"
            :width="3"
          ></v-progress-circular>
      </v-row>
      <v-subheader v-if="showModelsSwitch" class="">Моделі Комутаторів:
        <v-btn icon small class="ml-2" @click="$refs.addModelSwitch.show('add')">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-subheader>
      <v-row justify="center" v-if="showModelsSwitch">
        <v-col cols="12" md="10" lg="8">
          <v-simple-table >
            <template v-slot:default>
              <thead>
                <tr>
                <th class="text-left">Назва</th>
                <th class="text-left">Керований</th>
                <th class="text-left">Опції</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in modelsSwitch"
                  :key="item.name"
                >
                  <td>{{ item.name }}</td>
                  <td><v-icon v-if="item.is_managment" color="primary">mdi-check</v-icon></td>
                  <td>
                    <v-btn small title="Редагувати" icon  @click="$refs.addModelSwitch.show('change', item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn small title="Видалити" icon @click="deleteModelSwitch(item)">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <template v-else-if="mainSwitches.length !=0">
        <v-subheader >Вузлові комутатори:</v-subheader>
        <div class="mb-3" v-for="(item, index) in mainSwitches" :key="index" @click="getSwitchTree(item.id)">

            <v-chip  label outlined class="mr-2" color=""><b>{{ `${item.street} ${item.house}${item.letter}` }}</b>
              <span  class="ml-3 mr-2 grey--text" color="grey">{{ `${item.entrance} п-д | ${item.model.name}` }}</span>
            <span  class="mr-3 primary--text" color="primary"
            >{{ `${item.mac} | ${item.ip}` }}</span>
            </v-chip>
            <v-chip v-if="user && user.operator_id == 1" label class="mr-2" color=""><b>{{ operators_groups.find(x => x.id == item.operator ).name}}</b></v-chip>

        </div>
      </template>
      <template v-else-if="searchSwitches.length !=0">
        <v-subheader>Пошук:</v-subheader>
        <div class="mb-3" v-for="(item, index) in searchSwitches" :key="index" @click="getSwitchTree(item.id)">
            <v-chip  label outlined class="mr-2" color=""><b>{{ `${item.street} ${item.house}${item.letter}` }}</b>
              <span  class="ml-3 mr-2 grey--text" color="grey">{{ `${item.entrance} п-д | ${item.model.name}` }}</span>
            <span  class="mr-3 primary--text" color="primary"
            >{{ `${item.mac} | ${item.ip}` }}</span>
            </v-chip>
        </div>
      </template>
      <template v-else-if="switches.length !=0">
        <v-subheader>Дерево:</v-subheader>
        <v-treeview 
          :disabled="loading"
          @mouseleave="hoverSwitch = ''"
          ref="treeviewSwitches"
          activatable
          hoverable
          expand-icon="mdi-chevron-down"
          :items="switches"
          :active="activeSwitch"
        >
          <!--<template v-slot:prepend="{ item }">
            
          </template>-->
          <template v-slot:label="{ item }">
            <v-btn v-if="item.ancestors" x-small outlined color="error" class="mt-2 mb-1" @click="addParrentSwitch(item)"><v-icon small left>mdi-magnify</v-icon>Батьківський комутатор<br></v-btn>
            <div class="d-flex flex-wrap">
              <v-chip class="mr-2 my-1" :id="`_${item.id}`" @click.stop="goToParent(item.parent)" dark  label :color="activeParentElement == item.id ? 'accent' : 'dark-grey'">{{ item.parent_port }}</v-chip>
              <v-chip  label outlined class="mr-2 my-1" :color="item.color" @click="getSwitchTree(item.id)"><b>{{ `${item.street} ${item.house}${item.letter}` }}</b></v-chip>
              <v-chip  label outlined class="mr-2 my-1" my-1 color="grey" :title="item.note">{{ `${item.entrance} п-д | ${item.model.name}` }} <v-icon v-if="item.note" class="ml-2" small>mdi-message-outline</v-icon></v-chip>
              <v-chip  label outlined class="mr-3 my-1" color="primary">{{ `${item.mac} | ${item.ip}` }}</v-chip>
              <span class="switch-actions my-1" v-if="user && user.user_type != 2">
                <v-btn small title="Додати до комутатора" icon  @click="$refs.addSwitch.show('add', item)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn small title="Редагувати" icon  @click="$refs.addSwitch.show('change', item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn small title="Закріпити за дільницею" icon  @click="$refs.addDisrictSwitch.show('add', item)">
                  <v-icon>mdi-home-plus-outline</v-icon>
                </v-btn>
                <v-btn small title="Видалити" icon @click="deleteSwitch(item)">
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </span>
            </div>
          </template>
        </v-treeview>
        </template>
      
    </div>

    <!--<v-btn
        v-if="!drawer"
        bottom
        color="primary "
        dark
        fab
        fixed
        left
        @click="drawer = !drawer"
        >
        <v-app-bar-nav-icon />
    </v-btn>  --> 
    <AddSwitchModal ref="addSwitch" v-on:update-switch="getSwitchTree(historyRequestSwitchId)" :user="user" :streets="streets" :modelsSwitch="modelsSwitch" :operators_groups="operators_groups"/> 
    <AddDistrictSwitchModal ref="addDisrictSwitch" v-on:add-district="getListDistrictsSwitch()" :user="user" :operators_groups="operators_groups"/> 
    <AddModelSwitchModal ref="addModelSwitch" v-on:add-model-switch="getListModelsSwitch()" :user="user" :operators_groups="operators_groups"/> 
    <ConfirmDialog ref="confirmDialog"/>
    <keep-alive>
      <modalChoiseStreet ref="modalChoiseStreet" />
    </keep-alive>
  </v-container>
</template>

<script>
import axios from "axios"
import { mapGetters } from "vuex"
import "@/components/Switch/"
import Search from '@/components/Abonents/Search.vue'
import searchSwitchParse from "@/components/Switch/searchSwitchParse.js"
import modalChoiseStreet from '@/components/Tools/modalChoiseStreet.vue'
  export default {
    name: 'Switches',
    components: {
      Search,
      modalChoiseStreet
    },
    data: () => ({
      activeSwitch: [],
      showModelsSwitch: false,
      historyRequestSwitchId: '',
      search: '',
      prefixSearch: '',
      hoverSwitch: '',
      activeAll: false,
      activeParentElement: '',
      loadingDistricts: false,
      loading: false,
      modelsSwitch: [],
      districtsSwitch: [],
      searchSwitches: [],
      mainSwitches: [],
      switches: [],
      drawer: null,
      group: null,
      mini: true,
      districtsTab: null,
      districtsItems: [
        { title: 'ТРК', id: 0},
        { title: 'Кривбастелеком', id: 1},
        { title: 'СВ', id: 2},
        { title: 'Сателіт', id: 3},
        
      ],
      subDistrictsTab: null,
      subDistrictsItems: [],
      subDistrictsItemsKTK: [
        { title: 'КРЕС', id: 6},
        { title: 'Піонер'},
        { title: '173 квартал'},
        { title: 'Суха Балка'},
        { title: 'Пруди'},
      ]
    }),
    computed: {
      ...mapGetters({
        user: "auth/user",
        users_type: "users/users_type",
        operators_groups: 'filters/operators_groups',
        streets: 'streets/streets'
      }),
    },
    mounted(){
      this.$nextTick(function () {
        this.$store.dispatch('streets/fetchStreets')
        this.$store.dispatch('filters/fetchOperatorsGroups')
        this.getListDistrictsSwitch()
        this.getListModelsSwitch()
        //this.getMainSwitches()
      })
    },
    watch: {
      districtsTab(){
        if(this.districtsTab == 1) this.subDistrictsItems = this.subDistrictsItemsKTK
        else {
          this.subDistrictsItems = []
          this.subDistrictsTab = null
        }
      },
      activeSwitch(){
        console.log(this.activeSwitch)
      }
    },
    methods: {
      async searchStreetId(street_str){
        const {data} = await axios.get('/api/cabletv/list_street/', {params: {search: street_str}})
        if(data.results && data.results.length != 0){
          if(data.results.length == 1) return data.results[0].id
          else {
              const answer = await this.$refs.modalChoiseStreet.open(data.results)
              return answer.id
          }
        }else{ 
            this.$router.app.$sheet.show("Вулицю не знайдено")
        }
      },
      searchSwitchParse: searchSwitchParse,
      async startSearch(search){
        this.search = search 

        search && this.searchSwitch(await this.searchSwitchParse(search))
        
      },
      activatedAll(){
        this.activeAll = !this.activeAll
        this.$refs.treeviewSwitches.updateAll(this.activeAll) 
        
        
      },
      goToParent(parent){ 
        if(!parent) return
        this.activeParentElement = parent
        this.$vuetify.goTo(`#_${parent}`, {offset: 15})
      },
      viewStreet(id){
        //console.log(this.streets)
        let street = this.streets && this.streets.find(x => x.id == id) || false
        return street && street.street || id
      },
      getMainSwitches(){
        this.activeAll = false
        this.showModelsSwitch = false
        this.searchSwitches = []
        this.mainSwitches = []
        this.switches = []
        this.activeParentElement = ''
        this.loading = true;
        axios.get(`${this.$router.app.devUrlServiceSwitches}/api_network/get_switch/`)
        .then( response => {
          this.switches = []
          this.mainSwitches = response.data
        })
        .catch(() => {})
        .finally(() => (this.loading = false))
      },
      getSwitchTree(id){
        this.activeAll = false
        this.showModelsSwitch = false
        this.subDistrictsTab = null
        this.historyRequestSwitchId = id
        this.searchSwitches = []
        this.mainSwitches = []
        this.switches = []
        this.activeParentElement = ''
        this.loading = true;
        axios.get(`${this.$router.app.devUrlServiceSwitches}/api_network/get_switch_branch/${id}/`)
        .then( response => {
          this.switches = response.data
          this.mainSwitches = []
        })
        .catch(() => {})
        .finally(() => (this.loading = false))
      },
      async deleteSwitch(item){
        let question = await this.$refs.confirmDialog.open("Видалити комутатор?", "Ви впевнені, що хочете видалити комутатор?")
        if(question){
          this.activeParentElement = ''
          this.loading = true;

          axios({
            method: 'DELETE',
            url: `${this.$router.app.devUrlServiceSwitches}/api_network/edit_switch/${item.id}/`
          })
          .then( () => {
            this.$router.app.$snack.show('Комутатор видалено!')
            if(item.id == this.historyRequestSwitchId){
              this.getSwitchTree(item.parent)
            }else{
              this.getSwitchTree(this.historyRequestSwitchId)
            }
          })
          .catch(err => { this.$router.app.$sheet.show("Помилка!", err) })
          .finally(() => (this.loading = false))
        }
      },
      addParrentSwitch(item){
        this.switches[0].color = ''
        let temp_switch = item.ancestors
        item.ancestors = null
        temp_switch.children = [item]
        this.loading = true
        
        this.switches = [temp_switch]
        this.switches[0].color = 'error'
        setTimeout(() => {this.$refs.treeviewSwitches.updateAll(true); this.loading = false}, 100)
        
        //this.activatedAll()
        
      },
      searchSwitch(data_search){
        this.activeAll = false
        this.showModelsSwitch = false
        this.subDistrictsTab = null
        this.searchSwitches = []
        this.mainSwitches = []
        this.switches = []
        this.activeParentElement = ''
        this.loading = true;
        axios.get(`${this.$router.app.devUrlServiceSwitches}/api_network/search_switch_child/`, {params: data_search})
        .then( response => {
          //this.searchSwitches = response.data
          this.switches = response.data
        })
        .catch(() => {})
        .finally(() => (this.loading = false))
      },
      async deleteModelSwitch(item){
        let question = await this.$refs.confirmDialog.open("Видалити модель?", `Ви впевнені, що хочете видалити модель "${item.name}"?`)
        if(question){
          this.activeParentElement = ''
          this.loading = true;

          axios({
            method: 'DELETE',
            url: `${this.$router.app.devUrlServiceSwitches}/api_network/switch_models/${item.id}/`
          })
          .then( () => {
            this.$router.app.$snack.show('Модель видалено!')
            this.getListModelsSwitch()
          })
          .catch(err => { this.$router.app.$sheet.show("Помилка!", err) })
          .finally(() => (this.loading = false))
        }
      },
      getListModelsSwitch(){
        axios.get(`${this.$router.app.devUrlServiceSwitches}/api_network/get_switch_models/`)
        .then( response => { this.modelsSwitch = response.data } )
        .catch(() => {})
        .finally(() => ( this.loading = false ))
      },
      getListDistrictsSwitch(){
        this.loadingDistricts = true
        axios.get(`${this.$router.app.devUrlServiceSwitches}/api_network/district/`)
        .then( response => { this.subDistrictsItems = response.data } )
        .catch(() => {})
        .finally(() => ( this.loadingDistricts = false ))
      },
      async deleteDistrict(item){
        let question = await this.$refs.confirmDialog.open("Видалити дільницю?", `Ви впевнені, що хочете видалити дільницю "${item.name}"?`)
        if(question){
          this.activeParentElement = ''
          this.loading = true
          axios({
            method: 'DELETE',
            url: `${this.$router.app.devUrlServiceSwitches}/api_network/edit_district/${item.id}/`
          })
          .then( () => {
            this.$router.app.$snack.show('Дільницю видалено!')
            this.getListDistrictsSwitch()
          })
          .catch(err => { this.$router.app.$sheet.show("Помилка!", err) })
          .finally(() => (this.loading = false))
        }
      }
    }
  }
</script>

<style>


.v-treeview-node__root:hover .switch-actions{
  display:inline
}
.v-treeview-node--active .switch-actions{
  display:inline
}
.switch-actions{
  display: none
}
.v-list-item:hover .district-action{
  visibility: inherit;
}
.district-action{
  visibility: hidden;
}


	

</style>