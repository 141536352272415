<template>
        <v-dialog v-model="dialog" persistent max-width="400">
            <!--<template v-slot:activator="{ on }">
                <v-btn x-small icon absolute right v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
            </template>-->
            <v-card>
            <v-card-title>
            <span class="headline">{{ userData.title }} </span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>
                    <v-col cols="12">
                        <v-text-field 
                        label="Назва"
                        v-model="form_data.name"
                        name="name"
                        :rules="textRules"
                        type="text"
                        hide-details="auto"
                        >
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" v-if="!changeForm">
                        <v-text-field 
                        label="Комутатор"
                        v-model="switch_address"
                        name="switch_id"
                        :rules="textRules"
                        type="text"
                        hide-details="auto"
                        disabled
                        >
                        </v-text-field>
                    </v-col>
                    
                    <v-col cols="12" v-if="user && user.operator_id == 1">
                        <v-select
                        v-model="form_data.operator_group"
                        :rules="textRules"
                        :items="operators_groups"
                        label="Оператор"
                        :disabled="changeForm"
                        item-text="name"
                        item-value="id"
                        hide-details="auto"
                        ></v-select>
                    </v-col>
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer></v-spacer>

                <v-btn :loading="loadingSubmit" color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">
                    <template v-if="changeForm">Зберегти</template>
                    <template v-else>Додати</template>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
	export default {
        name: 'add-district-switch-modal',
        props: ["user", "operators_groups"],
		data: () => ({
            changeForm: false,
            loadingSubmit:false,
            item: {},
            userData: {},
            defaultUserData: {
                add: {title: "Додавання Дільниці", method: "POST", readonly: false, message: "Дільницю створено!"},
                change: {title: "Редагування Дільниці", method: "PATCH", readonly: true, message: "Данні дільниці змінено!"}
            },
            dialog: false,
            valid: true,
            form_data:{},
            switch_address: '',
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            emailRules: [
                v => !v && true || /.+@.+\..+/.test(v) || 'E-mail не валідний',
            ],
            
        }),
        mounted(){
            this.userData = JSON.parse(JSON.stringify(this.defaultUserData.add))
        },
        methods: {
            show(type, item){
                //console.log(order);
                if(type == 'add'){
                    this.changeForm = false
                    this.userData = JSON.parse(JSON.stringify(this.defaultUserData.add))
                    this.form_data.switch_id = item.id
                    this.switch_address = `${item.model.name}`
                    console.log(this.$parent)
                }else{
                    this.changeForm = true
                    this.userData = JSON.parse(JSON.stringify(this.defaultUserData.change));
                    this.form_data = JSON.parse(JSON.stringify(item))
                }
                
                
                this.dialog = true;
            },
            validate () {
                if (this.$refs.form.validate()) {
                this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog (){
                this.dialog = false
                this.reset() 
                this.changeForm = false
                this.userData = JSON.parse(JSON.stringify(this.defaultUserData.add));
            },
            sendForm() {
                //console.log(this.form_data);
                
                if(this.$refs.form.validate()){
                    this.loadingSubmit = true;
                    
                    axios({
                        method: this.userData.method,
                        url: `${this.$router.app.devUrlServiceSwitches}/api_network/district/${this.changeForm && this.form_data.id + '/' || ''}`,
                        data: this.form_data,
                    })
                    .then(() => {
                        //console.log(response);
                        this.loadingSubmit = false;
                        this.$emit('add-district')
                        this.$router.app.$snack.show(this.userData.message);
                        this.dialog = false;
                        this.reset();
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.loadingSubmit = false;
                        this.$router.app.$sheet.show("Помилка!", err);
                        
                        //console.log(this.$route.query.next)
                    });
                }
                
            }
        }
    }
</script>