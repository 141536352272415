<template>
        <v-dialog v-model="dialog" persistent max-width="500">
            <v-card>
            <v-card-title class="">
            <span class="headline">{{ modalData.title }} </span>
            </v-card-title>
            
            <v-card-text>
                
            <v-container class="">
                <v-form 
                ref="form"
                v-model="valid"
                lazy-validation>
                    <v-row>
                        <v-spacer />
                        <v-col cols="12" md=12 class="">  
                            <template >
                                <v-col v-for="field in fields" :key="field.name" cols="12" class="py-2 px-0">
                                    <!-- Search Street -->
                                    <template v-if="field.name == 'street_id' && !changeForm">
                                        
                                        <SearchStreet  v-on:search-new-id="setSearchValue" :rules="field.rules && textRules || notRules" :denseForm="true" :outlinedForm="true" />
                                        
                                    </template>
                                    <v-text-field v-else-if="field.name == 'street_id'"
                                        v-model="form_data.street"
                                        :label="field.title"
                                        :name="field.name"
                                        hide-details="auto"
                                        disabled
                                        outlined
                                        dense
                                    ></v-text-field>
                                    <!-- / Search Street -->

                                    <template v-else-if="field.name == 'parent'  && changeForm">
                                        
                                        <SearchSwitchField v-on:search-new-id="setSearchSwitchValue" :rules="field.rules && textRules || notRules" :denseForm="true" :outlinedForm="true" />
                                        
                                    </template>
                                    <v-text-field v-else-if="field.name == 'parent'"
                                        v-model="form_data.address"
                                        :label="field.title"
                                        :name="field.name"
                                        hide-details="auto"
                                        disabled
                                        outlined
                                        dense
                                    ></v-text-field>

                                    <v-select v-else-if="field.name == 'model'"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="form_data[field.name]"
                                    :name="field.name"
                                    :rules="field.rules && textRules || notRules"
                                    :items="field.values"
                                    item-text="name"
                                    item-value="id"
                                    :disabled="changeForm && field.disabled"
                                    >
                                    </v-select>

                                    <v-select v-else-if="field.type == 'select'"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="form_data[field.name]"
                                    :name="field.name"
                                    :rules="field.rules && textRules || notRules"
                                    :items="field.values"
                                    item-text="name"
                                    item-value="id"
                                    :clearable="field.clearable"
                                    :disabled="changeForm && field.disabled"
                                    >
                                    </v-select>

                                    <v-text-field v-else
                                    dense
                                    outlined
                                    hide-details="auto"
                                    :label="field.title"
                                    v-model="form_data[field.name]"
                                    :name="field.name"
                                    :rules="field.rules && textRules || notRules"
                                    :type="field.type"
                                    :disabled="changeForm && field.disabled"
                                    >
                                    </v-text-field>
                                </v-col>
                            </template>
                        </v-col>
                        <v-spacer />
                    </v-row>
                </v-form>
            </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary lighten-1" text @click="closeDialog()">Закрити</v-btn>
                <v-spacer />
                <v-btn :loading="loadingSubmit"
                color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" @click="sendForm()">Зберегти</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import axios from "axios"
    import { mapGetters } from "vuex"
	export default {
        components:{
            //
        },
        name: 'add-switch-modal',
        props: ['user', 'streets', 'modelsSwitch', 'operators_groups'],
		data: () => ({
            
            loadingSubmit:false,
            changeForm: false,
            item: {},
            modalData: {},
            defaultModalData: {
                add: {title: "Додати Комутатор", method: "POST", url: 'api_network/search_switch_child', readonly: false, message: "Комутатор додано!"},
                change: {title: "Редагувати Комутатор", method: "PATCH", url: 'api_network/edit_switch', readonly: true, message: "Дані комутатора змінено!"}
            },
            dialog: false,
            valid: true,
            form_data: {},
            priceTarif: 0,
            form_data_default:{
                parent: '', 
                parent_port: '', 
                street_id: '', 
                house: '', 
                letter: false, 
                entrance: '', 
                model: '', 
                mac: '', 
                ip: '', 
                serial: '', 
                note: '',
                operator: ''
            },
            fields: [
                { title: 'Батьківський комутатор', name: 'parent',     rules: false, type: 'text', disabled: false, value: '' },
                { title: 'Порт', name: 'parent_port', rules: true, type: 'number', disabled: false, value: '' },
                { title: 'Вулиця', name: 'street_id', rules: true, type: 'select', disabled: true, value: '', values: [] },
                { title: 'Будинок', name: 'house',    rules: true, type: 'number', disabled: true, value: '' },
                { title: 'Буква', name: 'letter',     rules: false, type: 'select', disabled: true, value: '', clearable: true, values: ['а','б','в','г','д','е','ж','з','и','к','л','м','н','о','п'] },
                { title: 'Підʼзд', name: 'entrance',  rules: false, type: 'number', disabled: false, value: '' },
                { title: 'Модель', name: 'model',     rules: false, type: 'text', disable: false, value: '', values: [] },
                { title: 'MAC Адреса', name: 'mac',   rules: false, type: 'text', disabled: false, value: '' },
                { title: 'IP Адреса', name: 'ip',     rules: false, type: 'text', disabled: false, value: '' },
                { title: 'Серійний номер', name: 'serial', rules: false, type: 'text', disabled: false, value: '' },
                { title: 'Примітка', name: 'note',    rules: false, type: 'text', disabled: false, value: '' },
                { title: 'Оператор', name: 'operator',  rules: false, type: 'select', disabled: false, value: '', values: [] },
            ],
            textRules: [
                v => !!v || "Це поле обов'язкове",
            ],
            notRules: [
                v => !!v || true ,
            ],
            
        }),
        computed: {
            ...mapGetters({
                //
            }),
        },
        
        mounted(){
            this.modalData = JSON.parse(JSON.stringify(this.defaultModalData.add))
            
        },
        watch:{
            form_data:{
                handler () {
                    //console.log("form_data GHANGE!")
                },
                deep: true,
            },
        },
        methods: {
            show(type, item){
                //console.log(order);
                this.createValuesField()
                if(type == "change"){
                    this.modalData = JSON.parse(JSON.stringify(this.defaultModalData.change))
                    this.changeForm = true
                    this.form_data = JSON.parse(JSON.stringify(item))
                }
                else{
                    this.modalData = JSON.parse(JSON.stringify(this.defaultModalData.add))
                    this.changeForm = false
                    this.form_data = JSON.parse(JSON.stringify(this.form_data_default))
                    this.$refs.form && this.reset()
                    this.form_data.parent = item.id
                    this.form_data.address = `${item.street} ${item.house} ${item.letter} (${item.ip})`
                    if(this.user?.operator_id == 1) this.form_data.operator = 1
                    
                }
                this.dialog = true
            },
            validate () {
                if (this.$refs.form.validate()) {
                    this.snackbar = true;
                }
            },
            reset () {
                this.$refs.form.reset();
            },
            closeDialog(){
                this.dialog = false
                this.reset()
            },
            clearModal(){
                this.changeForm = false
                this.modalData = JSON.parse(JSON.stringify(this.defaultModalData.add))  
            },
            setSearchValue(val){
                this.form_data.street_id = val
            },
            setSearchSwitchValue(val){
                this.form_data.parent = val
            },
            createValuesField(){
                let operatorsIndex = this.fields.indexOf(this.fields.find(x => x.name == "operator"))
                
                this.fields.find(x => x.name == "model").values = this.modelsSwitch 
                
                if(this.user?.operator_id == 1){ // Якщо оператор "Altair Group"
                    this.fields.find(x => x.name == "operator").values = this.operators_groups
                    this.form_data.operator = 1
                }else if(operatorsIndex != -1){
                    this.fields.splice(operatorsIndex, 1)
                }
            },
            getFieldsOfFormData(){
                // убираем пустые поля из объекта form_data
                let temp = {}
                for (let element in this.form_data) {
                    if (this.form_data[element] || this.form_data[element] === false) temp[element] = this.form_data[element]
                }
                return temp
            },
            sendForm() {
                if(this.$refs.form.validate()){
                    this.loadingSubmit = true;
                    if(typeof(this.form_data.model) == 'object'){
                        this.form_data.model = this.form_data.model.id
                    }
                    axios({
                        method: this.modalData.method,
                        url: `${this.$router.app.devUrlServiceSwitches}/${this.modalData.url}/${this.changeForm && this.form_data.id + '/' || ''}`,
                        data: this.getFieldsOfFormData(),
                    })
                    .then(() => {
                        //console.log(response);
                        this.$emit('update-switch');
                        this.$router.app.$snack.show(this.modalData.message);
                        this.closeDialog()
                    })
                    .catch(err => {
                        //reject(err)
                        //console.log(err);
                        this.$router.app.$sheet.show("Помилка!", err);  
                        //console.log(this.$route.query.next)
                    })
                    .finally(() => (this.loadingSubmit = false))
                }
                
            },
            
        }
    }
    
</script>